<template>
  <div id="Page-Intro" class="main-wrap ">
		<div class="flex w100">
			<div>
				<div class="main-box-menu-wrap"></div>
				<div class="main-box-menu">
					<div v-for="(item,index) in list" :key="index" class="flex middle ">
						<a class="a" :class="{active:currentIndex == index}" href="javascript:void(0)" @click="doSelectItem(item,index)">{{L(item.title)}}</a>
					</div>
				</div>
			</div>
			<div class="main-box-content w720 ">
				<div v-for="(item,index) in list" :key="index" class="list-content">
					<div :id="'HASH_'+index" class="content-hash"></div>
					<div class="title-h2">{{item.etitle}}</div>
					<div class="title-h1">{{L(item.title)}}</div>
					<!-- <div class="mt-10"></div> -->
					<div v-if="$root.lang =='CN'" v-html="L(item.content)" class="content"></div>
					<div v-if="$root.lang =='EN'" v-html="item.econtent" class="content"></div>
				</div>
			</div>
		</div>
  </div>
</template>

<script>
var timer;
export default {
  name: 'Page-Intro',
  components: {
    
  },
   data(){
		
    return {
			list:[],
			currentIndex:-1,
			currentData:{}
    };
  },
  async mounted () {
		var list = await this.request.zd.get("INTRO");
		if(list && list.length){
			this.currentIndex = 0;
			this.$set(this,'currentData',list[0]);
			list.forEach(item => {
				item.content = item.content.replace(/<br\/><br\/>/g,"<div class=\"mt-10\"></div>").replace(/<p><\/p>/g,"<div class=\"mt-5\"></div>").replace(/<p><br\/>/g,"<div class=\"mt-10\"></div>");
				item.econtent = item.econtent.replace(/<br\/><br\/>/g,"<div class=\"mt-10\"></div>").replace(/<p><\/p>/g,"<div class=\"mt-5\"></div>").replace(/<p><br\/>/g,"<div class=\"mt-10\"></div>");
			})
		}
		this.list = list || [];
		window.addEventListener("scroll", this.runScroll); 
		
  },
	destroyed() {
		window.removeEventListener('scroll', this.runScroll)
	},
  methods: {
		runScroll(){
			if(timer){clearTimeout(timer);}
			timer = setTimeout(this.handleScroll,80);
		},
		doSelectItem(item,index){
			this.currentIndex = index;
			this.$set(this,'currentData',item);
			this.$nextTick(()=>{
				this.$smoothScroll({
				  scrollTo: document.querySelector('#HASH_'+index)
				})
				
				// document.querySelector('#HASH_'+index).scrollIntoView(true)
			})
			
		},
		
		handleScroll() {
			console.log("handleScroll");
			//获取滚动时的高度
			let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			for(var i=0,l=this.list.length;i<l;i++){
				var top = document.querySelector('#HASH_'+i).offsetTop+178;
				if(top >= scrollTop){
					this.currentIndex = i;
					break;
				}
				if(i==this.list.length-1){
					this.currentIndex = this.list.length-1;
				}
			}
			
		}
  }
}
</script>

<style scoped lang="less">
.main-box-content{
	font-size:16px;
	padding-bottom:260px;
	// height:calc(100vh - 128px - 70px - 1px);
	// overflow: auto;
	.list-content{
		margin-bottom:80px;
		/deep/ul,/deep/ol{margin: 0 0 0 0;padding-left: 19px;}
		/deep/ul {
			padding-left: 2px;
			li{
				list-style-type: none;
				padding-left:19px;
				background:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2LjY2OSIgaGVpZ2h0PSI2LjY2OSIgdmlld0JveD0iMCAwIDYuNjY5IDYuNjY5Ij4KICA8ZyBpZD0i57uEXzE4NSIgZGF0YS1uYW1lPSLnu4QgMTg1IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtNjAwLjM2IC0yOTA1Ljg0NSkiPgogICAgPGNpcmNsZSBpZD0i5qSt5ZyGXzEwMSIgZGF0YS1uYW1lPSLmpK3lnIYgMTAxIiBjeD0iMi44MzUiIGN5PSIyLjgzNSIgcj0iMi44MzUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYwMC44NiAyOTA2LjM0NSkiIGZpbGw9Im5vbmUiIHN0cm9rZT0iIzA0MDAwMCIgc3Ryb2tlLW1pdGVybGltaXQ9IjEwIiBzdHJva2Utd2lkdGg9IjEiLz4KICA8L2c+Cjwvc3ZnPgo=")  no-repeat left 7px ;
			}
		}
		/deep/p{line-height: 1.5;font-size:16px;}
		/deep/a:link,
		/deep/a:visited,
		/deep/a:hover,
		/deep/a:active{
			color: #000;
			text-decoration:underline
		}
		.content,/deep/.content *{
			font-family: 'Roboto';
			
		}
		/deep/table{
			
			tr:nth-of-type(odd){
				background-color: #F2F2F3;
			}
			tr{
				td{
					border-right:8px solid #fff;
					padding:7px 8px 4px;
					line-height: 1.5;
					// width:100%;
					&:first-child{
						word-break: keep-all;
						white-space: nowrap;
						width:200px;
					}
					&:last-child{
						border-right:0px solid #fff;
					}
				}
			}
			
		}
	}
	.content-hash{
		position:absolute;
		margin-top:-178px;
	}
	
}
</style>